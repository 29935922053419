/* eslint-disable indent, quotes */
export const TRANSLATION_FR = {
  i18n: {
    language: "Langue",
    languageName: "Français",
  },
  rackReturnLauncher: {
    continueToReturnForm: "Passer au formulaire de retrait",
    rackIdFieldPlaceholder: "Châssis N° prêt pour le retrait",
  },
  invitationGame: {
    company: "Company",
    email: "E-mail",
    game1: "Game",
    altGame1: "Alt Game 1",
    altGame2: "Alt Game 2",
    numParticipants: "Number of Participants",
    subjectFmt: "Hockey Game Registration by {}",
    bodyHtmlFmt: "<p>Hockey Game Registration</p><p><ul><li>Company: <strong>{company}</strong></li><li>E-mail: <strong><a href=\"{email}\">{email}</a></strong></li><li>Game: <strong>{game}</strong></li><li>Alt Game 1: <strong>{altGame1}</strong></li><li>Alt Game 2: <strong>{altGame2}</strong></li><li>Number of Participants: <strong>{numParticipants}</strong></li></ul></p>",
  },
  rackReturn: {
    pickupLocationFmt: "Lieu de Collecte Châssis n° {}",
    pickupStreet: "Adresse",
    pickupCity: "Lieu",
    pickupCityPlaceholder: "Lieu de Collecte",
    doTrackLocation: "✓ position",
    dontTrackLocation: "⏸ position",
    errorTrackingLocation: "✖ Erreur",
    companyName: "Entreprise",
    enterYourCompany: "Entre ton entreprise",
    contactName: "Contacte",
    enterYourName: "Entre ton nom",
    returnForRackFmt: "Formulaire de retour pour le châssis n° {}",
    comments: "Commentaires",
    enterAnyComments: "Ajoute des commentaires",
    submit: "Envoyer",
    companyNameEmptyError: "Le nom du client est requis",
    contactNameEmptyError: "Le nom du contact est requis",
    addressEmptyError: "Adresse ou point de carte requis",
    errorSendingEmail: "⚠ Erreur lors de la soumission du formulaire",
  },
  rackReturnConfirmation: {
    thankYou: "Merci. Nous y serons sous peu ...",
  },
  rackReturnEmail: {
    subjectFmt: "Retour du châssis n° {}",
    rackIdReadyAtCompanyInCityFmt: "Le châssis n° {châssisId} est prêt à être récupéré chez {companyName} à {city}",
    rackIdReadyInCityFmt: "Le châssis n° {châssisId} est prêt à être récupéré à {city}",
    bodyHtmlFmt: "<p>{heading}</p><p><ul><li>Firma: <strong>{companyName}</strong></li><li>Contacte: <strong>{contactName}</strong></li><li>Adresse: <strong>{address}</strong></li><li>Langue: <strong>{language}</strong></li></ul></p><p>Commentaires:<br />{comments}</p>",
  },
};
