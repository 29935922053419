/* eslint-disable quotes */
import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from 'i/logo.svg';
import i18n from 'utils/i18n';
import ReturnRackLangList from './ReturnRackLangList';

const ReturnRackHeader = () => {
	// a bit redundant but it's how it comes from WordPress
	const contact_info = {
		address_1: {
			en: "Oberwilstrasse 3",
			de: "Oberwilstrasse 3",
			fr: "Oberwilstrasse 3",
			it: "Oberwilstrasse 3",
		},
		address_2: {
			en: "CH-3253 Schnottwil",
			de: "CH-3253 Schnottwil",
			fr: "CH-3253 Schnottwil",
			it: "CH-3253 Schnottwil",
		},
		contact_1_phone: "+41 32 312 03 00",
		contact_2_email: "spedition@cdr.ch",
	};
	const langApp = i18n.language;

	const telLink = (telNr) => {
		let cleanNumber = telNr.replace(/[^\d+]/g, '');
		if (cleanNumber.startsWith('+41') || cleanNumber.startsWith('0041')) {
			if (cleanNumber[0] === '+') {
				cleanNumber = cleanNumber.substring(3); // Remove the '+41' country code
			} else {
				cleanNumber = cleanNumber.substring(4); // Remove the '0041' country code
			}
			if (!cleanNumber.startsWith('0') && cleanNumber.length === 9) {
				cleanNumber = `0${cleanNumber}`;
			}
		}
		return `tel:${cleanNumber}`;
	};

	return (
		<header className="rr_header">
			<div className="rr_header_in">
				<div className="rr_header_logo_address">
					<Link
						className="rr_header_logo"
						to="/"
					>
						<img
							className="rr_header_logo_img" // rr_logo
							src={LogoImage}
							alt="CDR Logo"
						/>
					</Link>
					<div className="rr_header_info_list_w">
						<ul className="rr_header_info_list">
							{
								Object.keys(contact_info).map((key, index) => {
									let link = null;

									if (key.includes('phone')) {
										link = telLink(contact_info[key].trim());
									} else if (key.includes('email')) {
										link = `mailto:${contact_info[key].trim()}`;
									}

									return (
										<li className="rr_header_info_item" key={index}>
											{link ? (
												<a className="rr_header_info_link" href={`${link}`}>{contact_info[key]}</a>
											) : (
												<div className="rr_header_info_item_descr">{contact_info[key][langApp]}</div>
											)}
										</li>
									);
								})
							}
						</ul>
					</div>
				</div>
				<ReturnRackLangList />
			</div>
		</header>
	);
};

export default ReturnRackHeader;
