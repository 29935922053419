import React, {
	useRef,
	useState,
} from 'react';
import RackImage from 'i/rack/rack.jpg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RackReturnLauncherFrame = () => {
	const $buttonRef = useRef();
	const $rackIdRef = useRef();
	const [rackId, setRackId] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation('rackReturnLauncher');

	const handleRackIdBlur = (e) => {
		const cl = $rackIdRef.current?.classList;
		if (e.target.value) {
			setRackId(e.target.value);
			cl.remove('glow');
			cl.add('valid');
		} else {
			cl.remove('valid');
			setRackId(false);
		}
	};

	const submitRackReturn = (e) => {
		e.preventDefault();
		const id = rackId || e.target.value;
		if (id) {
			navigate(`/retour/${id}`, {
				state: { source: 'website' },
			});
		} else {
			const cl = $rackIdRef.current?.classList;
			if (cl) {
				cl.add('glow');
				cl.add('shake');
				setTimeout(() => $rackIdRef.current?.classList.remove('shake'), 500);
			}
		}
	};

	const handleRackIdKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleRackIdBlur(e);
			submitRackReturn(e);
		}

		const isControlKey = (
			e.key === 'Backspace'
			|| e.key === 'Delete'
			|| e.key === 'ArrowLeft'
			|| e.key === 'ArrowRight'
		);
		const isDigit = (e.key >= '0' && e.key <= '9');
		const hasSelection = e.target.selectionStart !== e.target.selectionEnd;

		if (!(isControlKey || isDigit)
			|| (isDigit && e.target.value.length >= 3 && !hasSelection)) {
			e.preventDefault();
		}
	};

	return (
		<form className="rr_ln_popup">
			<div className="rr_ln_rackpick">
				<img src={RackImage} alt="" />
				<input
					className="rr_ln_form_input"
					type="number"
					maxLength={3}
					onBlur={handleRackIdBlur}
					onKeyDown={handleRackIdKeyDown}
					placeholder=""
					ref={$rackIdRef}
				/>
			</div>
			<button type="submit" className="btn_v2 rr_ln_form_submit" onClick={submitRackReturn}>
				{t('continueToReturnForm')}
			</button>
		</form>
	);
};

export default RackReturnLauncherFrame;
