/* eslint-disable quotes,react/jsx-one-expression-per-line,camelcase */
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import { BodyBg } from 'components/BodyBg';
import { Spinner } from 'components/Spinner';
import ReturnRackConfirmation from 'components/ReturnRack/ReturnRackConfirmation';
import ReturnRackHeader from 'components/ReturnRack/ReturnRackHeader';
import ReturnRackMap from 'components/ReturnRack/ReturnRackMap';
import PosImage from 'i/icons/pos.svg';
import i18n from 'utils/i18n';
import emailjs from '@emailjs/browser';

const ReturnRackPage = () => {
	const [displayedStreet, setDisplayedStreet] = useState('');
	const [streetKey, setStreetKey] = useState(0);
	const [displayedCity, setDisplayedCity] = useState('');
	const [cityKey, setCityKey] = useState(0);
	const [manualAddress, setManualAddress] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [mapData, setMapData] = useState({ resolved: false });
	const [companyName, setCompanyName] = useState('');
	const [contactName, setContactName] = useState('');
	const [comments, setComments] = useState('');
	const { id: rackId } = useParams();
	const useGeolocation = useRef("geolocation" in navigator);
	const geoWatcher = useRef(null);
	const [trackLocation, setTrackLocation] = useState(true);
	const [errorTrackingLocation, setErrorTrackingLocation] = useState(false);
	const formRef = useRef(null);
	const { t } = useTranslation('rackReturn');
	const location = useLocation();
	const { pageEntryPoint } = location.state || { pageEntryPoint: 'qr' }; // 'website' or 'qr'
	const langDE = { lng: 'de' };
	const optionalSubtitle = null;
	const langApp = i18n.language;

	const positionNotAvailable = (e) => {
		if (e) {
			if (!errorTrackingLocation) {
				setErrorTrackingLocation(true);
			}
			// switch (e.code) {
			// 	case e.POSITION_UNAVAILABLE: break;
			// 	case e.PERMISSION_DENIED: break;
			// 	case e.TIMEOUT: break;
			// 	case e.UNKNOWN: break;
			// }
		}
	};

	const updatePosition = useCallback(position => {
		const coords = position?.coords;
		if (coords?.latitude) {
			const { latitude, longitude, accuracy } = coords;
			if (!mapData.coordinates || mapData.coordinates.latitude !== latitude || mapData.coordinates.longitude !== longitude) {
				setMapData({
					resolved: false,
					coordinates: { latitude, longitude, accuracy },
				});
			}
		} else {
			positionNotAvailable();
		}
	}, [mapData]);

	const startLocationTracking = () => {
		if (useGeolocation.current) {
			if (geoWatcher.current) {
				return;
			}
			geoWatcher.current = navigator.geolocation.watchPosition(
				updatePosition,
				positionNotAvailable,
				{
					enableHighAccuracy: true, // Request the best possible results
					timeout: 10000, // Maximum time to wait for a location (10 seconds)
					maximumAge: 0, // Accept only the freshest data
				},
			);
		} else {
			positionNotAvailable();
		}
	};

	const stopLocationTracking = () => {
		navigator.geolocation.clearWatch(geoWatcher.current);
		geoWatcher.current = null;
	};

	const toggleGeoWatcher = () => {
		if (trackLocation) {
			stopLocationTracking();
		} else {
			startLocationTracking();
			if (errorTrackingLocation) {
				// reset tracking error
				setErrorTrackingLocation(false);
			}
		}
		setTrackLocation(!trackLocation);
	};

	const onAddressFieldFocus = () => {
		// disable tracking when entering input field
		// otherwise address is reset every time the position is reacquired
		if (trackLocation) {
			toggleGeoWatcher();
		}
	};

	const onAddressFieldBlur = (e) => {
		const val = e.target.value;
		if (e.target.id === 'pickupStreet') {
			if (displayedStreet !== val) {
				setDisplayedStreet(val);
				if (!manualAddress) {
					setManualAddress(true);
				}
			}
		} else if (e.target.id === 'pickupCity') {
			if (displayedCity !== val) {
				setDisplayedCity(val);
				if (!manualAddress) {
					setManualAddress(true);
				}
			}
		}
	};

	const sendRackPickupEmail = async (values) => {
		const encodeHTML = (str) => {
			return `${str}`.replace(/[&<>"']/g, (tag) => {
				const charsToReplace = {
					'&': '&amp;',
					'<': '&lt;',
					'>': '&gt;',
					'"': '&quot;',
					"'": '&#39;',
				};
				return charsToReplace[tag] || tag;
			});
		};
		const gmapLink = `https://www.google.ch/maps/search/${encodeURIComponent(mapData.displayedAddress.join(', '))}`;
		const coords = mapData.coordinates;
		const latlon = coords ? `${coords.latitude},${coords.longitude}` : null;
		const spacedLatlon = coords ? `${coords.latitude}, ${coords.longitude}` : null;
		const gmapCoordsLink = (
			coords
				? `https://www.google.ch/maps/?q=@${encodeURIComponent(latlon)}`
				: null
		);
		const encodedCoordsInfo = (gmapCoordsLink
			? `<a href="${gmapCoordsLink}">${encodeHTML(`(${spacedLatlon})${coords.accuracy ? ` (+/-${coords.accuracy}m)` : ''}`)}</a>`
			: ''
		);
		const locationTypeDe = (locType) => {
			switch (locType) {
				case 'live': return 'GPS';
				case 'map-point': return 'Punktauswahl in Karte';
				case 'address': return 'Addresseingabe';
				default: return t;
			}
		};
		const headingVars = {
			rackId,
			companyName,
			city: displayedCity,
		};
		const vars = {
			rackId,
			heading: (
				values.companyName
					? t('rackReturnEmail:rackIdReadyAtCompanyInCityFmt', langDE)
					: t('rackReturnEmail:rackIdReadyInCityFmt', langDE)
			).replace(/\{(\w+)\}/g, (_match, key) => encodeHTML(headingVars[key]) || ''),
			address: `${encodeHTML(mapData.displayedAddress.join(', '))} <a href="${gmapLink}">(Google Maps)</a>`,
			comments: encodeHTML(comments).replace(/\n/g, '<br>'),
			companyName: encodeHTML(companyName),
			contactName: encodeHTML(contactName),
			coordinates: encodedCoordsInfo,
			language: encodeHTML(langApp),
			locationType: encodeHTML(locationTypeDe(mapData.locationType)),
			navigator: encodeHTML(navigator?.userAgent),
			pageEntryPoint: encodeHTML(pageEntryPoint === 'qr' ? 'QR Scan' : 'Webseite'),
		};
		const htmlEmailBody = t('rackReturnEmail:bodyHtmlFmt', langDE).replace(/\{(\w+)\}/g, (_match, key) => vars[key] || '');
		const emailProps = {
			subject: t('rackReturnEmail:subjectFmt', langDE).replace('{}', rackId),
			html_body: htmlEmailBody,
			from_name: 'CDR Retouren',
			from_email: 'retour@cdr.ch',
			to_email: 'spedition@cdr.ch',
			cc_email: 'gabriel@cdr.ch',
			bcc_email: 'andy@bitcreed.us',
		};

		await emailjs.send(
			process.env.REACT_APP_EMAILJS_SERVICE_ID,
			process.env.REACT_APP_EMAILJS_GENERIC_TEMPLATE_ID,
			emailProps,
			{
				publicKey: process.env.REACT_APP_EMAILJS_USER_ID,
			},
		);
	};

	const onMapDrag = useCallback(() => {
		if (trackLocation) {
			toggleGeoWatcher();
		}
	});

	useEffect(() => {
		startLocationTracking();
		document.body.classList.add('page_rr');
	}, []);

	useEffect(() => {
		if (manualAddress && displayedStreet !== '' && displayedCity !== ''
			&& (!mapData.displayedAddress
				|| displayedStreet !== mapData.displayedAddress[0]
				|| displayedCity !== mapData.displayedAddress[1]
			)) {
			setMapData({ resolved: false, displayedAddress: [displayedStreet, displayedCity] });
		}
	}, [displayedStreet, displayedCity, manualAddress]);

	useEffect(() => {
		if (mapData.resolved) {
			// coords are resolved, switch to map-only entry
			setDisplayedStreet(mapData.displayedAddress[0]);
			setStreetKey(streetKey + 1); // remount component to apply defaultValue
			setDisplayedCity(mapData.displayedAddress[1]);
			setCityKey(cityKey + 1); // remount component to apply defaultValue
			if (mapData.locationType === 'map-point') {
				setManualAddress(false);
				if (trackLocation) {
					toggleGeoWatcher();
				}
			}
		}
	}, [mapData]);

	useEffect(() => {
		const ref = formRef.current;
		if (!ref) return;
		if (ref && !ref.isValid) {
			ref.validateForm();
		}
	}, [i18n.language]);

	const positionStatus = trackLocation
		? (errorTrackingLocation
			? 'error'
			: (mapData?.coordinates?.accuracy < 40 ? 'found' : 'acquiring'))
		: 'off';

	return (
		<>
			<ReturnRackHeader />
			<BodyBg />
			<div className="wrapper">
				<section className="section">
					<div className="rr_wrapper">
						{isSubmitted
							? <ReturnRackConfirmation />
							: (
								<>
									<div className="rr_block rr_block__map">
										<div className="rr_block_heading">
											<div className="rr_block_heading__icon">
												{rackId}
											</div>
											<h2 className="rr_block_heading__title">{t('returnForRackFmt').replace('{}', rackId)}</h2>
											{optionalSubtitle && (
												<div className="rr_block_heading__subtitle">{optionalSubtitle}</div>
											)}
										</div>
										<ReturnRackMap mapData={mapData} setMapData={setMapData} onMapDrag={onMapDrag} />
									</div>

									<div className="rr_block rr_block__form">
										<div className="rr_block_heading">
											<div className="rr_block_heading__icon">
												<img alt="pos" src={PosImage} />
											</div>
											<h2 className="rr_block_heading__title">{t('pickupLocationFmt').replace('{}', rackId)}</h2>
											<button
												type="button"
												className={`rr_track_position rr_track_position__${positionStatus} btn_v2 nolabel`}
												onClick={toggleGeoWatcher}
											>
												{
													trackLocation
														? (errorTrackingLocation
															? t('errorTrackingLocation')
															: t('doTrackLocation'))
														: t('dontTrackLocation')
												}
											</button>
										</div>
										<Formik
											innerRef={formRef}
											initialValues={{
												rackId,
												language: langApp,
												pickupStreet: '',
												pickupCity: '',
												companyName: '',
												contactName: '',
												comments: '',
											}}
											validate={(_values) => {
												const errors = {};
												if (!mapData.displayedAddress) {
													errors.pickupStreet = t('addressEmptyError');
												}

												return errors;
											}}
											onSubmit={async (values, {
												setSubmitting, setFieldTouched, setFieldError,
											}) => {
												try {
													setSubmitting(true);
													await sendRackPickupEmail(values);
													setIsSubmitted(true);
												} catch {
													setFieldTouched('submit', true);
													setFieldError('submit', t('errorSendingEmail'));
												} finally {
													setSubmitting(false);
												}
											}}
											validateOnChange={false}
											validator={() => ({})}
										>
											{({
												isSubmitting,
												handleSubmit,
												setFieldValue,
											}) => {
												return (
													<form className="rr_section_item rr_form" onSubmit={handleSubmit} noValidate>
														<input
															type="hidden"
															name="rackId"
															value={rackId}
															readOnly
														/>
														<input
															type="hidden"
															name="language"
															value={langApp}
															readOnly
														/>

														<div className="rr_form_item">
															<label htmlFor="pickupStreet">
																<span className="rr_label_text">{t('pickupStreet')}*</span>
																<ErrorMessage component="div" name="pickupStreet" className="rr_form_error" />
															</label>
															<input
																id="pickupStreet"
																name="pickupStreet"
																className="rr_form_input"
																type="text"
																key={streetKey}
																defaultValue={displayedStreet}
																onBlur={onAddressFieldBlur}
																onFocus={onAddressFieldFocus}
																placeholder={t('pickupStreet')}
															/>
														</div>

														<div className="rr_form_item">
															<label>
																<span className="rr_label_text">{t('pickupCity')}*</span>
																<ErrorMessage component="div" name="pickupCity" className="rr_form_error" />
															</label>
															<input
																id="pickupCity"
																name="pickupCity"
																className="rr_form_input"
																type="text"
																key={cityKey}
																defaultValue={displayedCity}
																onBlur={onAddressFieldBlur}
																onFocus={onAddressFieldFocus}
																placeholder={t('pickupCityPlaceholder')}
															/>
														</div>

														<div className="rr_form_item">
															<label htmlFor="companyName">
																<span className="rr_label_text">{t('companyName')}</span>
																<ErrorMessage component="div" name="companyName" className="rr_form_error" />
															</label>
															<input
																id="companyName"
																name="companyName"
																className="rr_form_input"
																type="text"
																defaultValue={companyName}
																onBlur={(e) => {
																	setFieldValue('companyName', e.target.value);
																	setCompanyName(e.target.value);
																}}
																placeholder={t('enterYourCompany')}
															/>
														</div>

														<div className="rr_form_item">
															<label htmlFor="contactName">
																<span className="rr_label_text">{t('contactName')}</span>
																<ErrorMessage component="div" name="contactName" className="rr_form_error" />
															</label>
															<input
																id="contactName"
																name="contactName"
																className="rr_form_input"
																type="text"
																defaultValue={contactName}
																onBlur={(e) => {
																	setFieldValue('contactName', e.target.value);
																	setContactName(e.target.value);
																}}
																placeholder={t('enterYourName')}
															/>
														</div>

														<div className="rr_form_item">
															<label htmlFor="comments">
																<span className="rr_label_text">{t('comments')}</span>
																<ErrorMessage component="div" name="comments" className="rr_form_error" />
															</label>
															<textarea
																id="comments"
																name="comments"
																className="rr_form_input rr_comments_field"
																defaultValue={comments}
																onChange={(e) => {
																	setFieldValue('comments', e.target.value);
																	setComments(e.target.value);
																}}
																placeholder={t('enterAnyComments')}
															/>
														</div>

														<div className="rr_form_item rr_form_item__submit">
															<button type="submit" className="btn_v2 nolabel" disabled={isSubmitting}>{isSubmitting ? <Spinner /> : t('submit')}</button>
															<ErrorMessage component="div" name="submit" className="rr_form_error rr_form_error__submit" />
														</div>
													</form>
												);
											}}
										</Formik>
									</div>
								</>
							)}
					</div>
				</section>
			</div>
		</>
	);
};

export default ReturnRackPage;
