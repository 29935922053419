import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Transition } from 'react-transition-group';
import { ReactComponent as SmartRetourIcon } from 'i/rack/SmartRetour.svg';
import { gsap } from 'gsap';
import { UiContext } from 'context/ui';
import { ReturnRackLauncherFrame } from 'components/ReturnRack';

const RackReturnLauncher = () => {
	const $bgRef = useRef();
	const $buttonRef = useRef();
	const [isOpen, setOpen] = useState(false);
	const { isMainMenuOpen } = useContext(UiContext);

	const onEntering = (node) => {
		gsap.fromTo(node, {
			opacity: 0,
			height: 0,
			ease: 'power3.InOut',
			duration: 0.3,
		}, {
			opacity: 1,
			height: 'auto',
		});
	};
	const onExit = (node) => {
		gsap.fromTo(
			node,
			{
				opacity: 1,
				height: 'auto',
			},
			{
				opacity: 0,
				height: 0,
				ease: 'power3.InOut',
				duration: 0.3,
			},
		);
	};

	const toggleRackReturnForm = () => {
		setOpen(!isOpen);
	};

	const bgClickHandler = (e) => {
		if (e.target === $bgRef.current && isOpen) {
			setOpen(false);
		}
	};

	// keep bottom alignment in sync with crip-chat's
	useEffect(() => {
		const crispChatbox = document.getElementById('crisp-chatbox');
		if (!crispChatbox || !$buttonRef.current) return undefined;

		const updateButtonPos = () => {
			if (!crispChatbox || !$buttonRef.current) return;
			if (crispChatbox.getAttribute('data-full-view') === 'true') {
				$buttonRef.current.classList.add('crisp-chatbox-full-view');
			} else {
				$buttonRef.current.classList.remove('crisp-chatbox-full-view');
			}
		};

		updateButtonPos();
		const observer = new MutationObserver(updateButtonPos);
		observer.observe(crispChatbox, { attributes: true, attributeFilter: ['data-full-view'] });

		return () => {
			observer?.disconnect();
		};
	}, []);

	/* eslint-disable jsx-a11y/click-events-have-key-events */
	/* eslint-disable jsx-a11y/no-static-element-interactions */
	if (isMainMenuOpen) {
		return null;
	}
	return (
		<div className={`rr_ln${isOpen ? ' open' : ''}`} onClick={bgClickHandler} ref={$bgRef}>
			<button type="button" className="rr_ln_button" onClick={toggleRackReturnForm} ref={$buttonRef}>
				{
					isOpen
						? <span className="rr_ln_button_icon__close" />
						: <SmartRetourIcon className="rr_ln_button_icon__open" />
				}
			</button>
			<Transition
				in={isOpen}
				timeout={1800}
				onExit={onExit}
				onEntering={onEntering}
				mountOnEnter
				unmountOnExit
			>
				<ReturnRackLauncherFrame />
			</Transition>
		</div>
	);
};

export default RackReturnLauncher;
